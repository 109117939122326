<template>
    <content-view v-if="isPageLoaded" class="w-100">
        <div class="knowledge-base-control">
            <div class="knowledge-base-control__row">
                <div class="knowledge-base-control__col">
                    <div class="knowledge-base-label">
                        <div class="knowledge-base-label__row">
                            <div class="knowledge-base-label__col">
                                <span class="knowledge-base-label__name">
                                    Сортировка:
                                </span>
                            </div>

                            <div class="knowledge-base-label__col">
                                <v-select2
                                    class="knowledge-base-label__field"
                                    :reduce="label => label.value"
                                    :clearable="false"
                                    :searchable="false"
                                    :options="sortFilterMaterialsList"
                                    v-model="sortFilterMaterialsSelected"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="knowledgeBase.is_editor"
                    class="knowledge-base-control__col"
                >
                  <button
                    type="button"
                    class="knowledge-base__material__btn"
                    aria-label="Edition statistics"
                    title="Статистика редакции по Базе знаний"
                    @click="openEditionStatistics"
                  >
                    <icon height="12" width="12" icon-color="#989CAE">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6381 2.627H15.7587C16.0951 2.627 16.3701 2.90187 16.3701 3.2382V11.3888C16.3701 11.7251 16.0951 12 15.7587 12H13.6381C13.3017 12 13.0269 11.7251 13.0269 11.3888V3.2382C13.0269 2.90187 13.3017 2.627 13.6381 2.627ZM0.611411 8.22402H2.73208C3.06841 8.22402 3.34328 8.4991 3.34328 8.83543V11.3888C3.34328 11.7251 3.06841 12 2.73208 12H0.611411C0.275074 12 0 11.7251 0 11.3888V8.83543C0 8.4991 0.275074 8.22402 0.611411 8.22402ZM4.95356 5.16433H7.07423C7.41057 5.16433 7.68564 5.4394 7.68564 5.77554V11.3888C7.68564 11.7251 7.41057 12 7.07423 12H4.95356C4.61743 12 4.34236 11.7251 4.34236 11.3888V5.77554C4.34236 5.4394 4.61743 5.16433 4.95356 5.16433ZM9.29592 0H11.4166C11.7527 0 12.0278 0.275074 12.0278 0.611411V11.3888C12.0278 11.7251 11.7527 12 11.4166 12H9.29592C8.95958 12 8.68451 11.7251 8.68451 11.3888V0.611411C8.68451 0.275074 8.95958 0 9.29592 0Z"/>
                    </icon>
                  </button>

                  <router-link
                    :to="`/knowledge/edit/`"
                    class="knowledge-base__material__btn"
                  >
                    <icon height="12" width="12" icon-color="#989CAE">
                      <path
                          d="M0 9.49953V11.9999H2.50035L9.87802 4.62217L7.37767 2.12183L0 9.49953Z"
                      />
                      <path
                          d="M11.5985 1.7519L10.0416 0.195027C9.78154 -0.065009 9.35813 -0.065009 9.0981 0.195027L7.87793 1.4152L10.3783 3.91554L11.5984 2.69537C11.8585 2.43534 11.8585 2.01193 11.5985 1.7519Z"
                      />
                    </icon>
                  </router-link>
                </div>
            </div>
        </div>

        <div class="material__content editor__content" id="page-print">
            <new-editor
                class="content__editor"
                :data="knowledgeBase.text"
                :editable="false"
                top-placeholder="Название материала"
                bottom-placeholder="Описание материала"
            />
        </div>
    </content-view>
    <loading-spinner v-else />
</template>

<script>
    import ContentView from '@/views/menu/ContentView'
    import session from '@/api/session'
    import { metaTitle } from '@/mixins/meta_title'
    import LoadingSpinner from '@/components/LoadingSpinner'
    import { mapGetters, mapActions } from 'vuex'
    import NewEditor from '@/components/editor/newEditor'

    export default {
        name: 'KnowledgeBase',
        mixins: [metaTitle],
        data() {
            return {
                baseName: 'База знаний',
                timer: null,
                knowledgeBase: {},
                isPageLoaded: false,
                sortFilterMaterialsSelected: window.localStorage.getItem('sortMaterial') ||  'date',
                sortFilterMaterialsList: [
                    {
                        label: 'По дате создания',
                        value: 'date'
                    },
                    {
                        label: 'По алфавиту',
                        value: 'title'
                    }
                ]
            }
        },
        async created() {
            try {
                this.sortMaterialsList(this.sortFilterMaterialsSelected)

                const request = session.get(
                    `/api/v1/material/${this.knowledgeBaseId}/`
                )
                const response = await request
                this.knowledgeBase = {
                    ...response.data,
                    text: response.data.text.indexOf('<menu id=') + 1 ? response.data.text : response.data.text + '<menu id=""></menu>'
                }

                this.isPageLoaded = true
            } catch (error) {
                this.$router.push('/404')
            }
        },
        watch: {
            sortFilterMaterialsSelected: {
                handler: function(val) {
                    this.sortMaterialsList(val)
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters('default_data', ['knowledgeBaseId']),
            setTitle() {
                return this.baseName
            }
        },
        components: {
            NewEditor,
            ContentView,
            LoadingSpinner,
            Icon: () => import('@/components/icon/Icon')
        },
        methods: {
            ...mapActions('knowledge_base', ['sortMaterialsList']),

            openEditionStatistics() {
                const ModalEditionStats = () => import('@/components/knowledge_base/modals/MaterilEditionStatistics.vue');

                this.$modal.show(ModalEditionStats, {}, {
                    name: 'edition-stats',
                    adaptive: true,
                    maxWidth: 620,
                    width: '100%',
                    height: 'auto'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .knowledge-base {
        &-control {
            margin: 0 0 35px;
            &__row {
                margin: -10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__col {
                margin: 10px;
                display: flex;
                align-items: center;
            }
        }

        &__material {
            margin-top: 30px;
            &__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                border: 2px solid #e6ebf3;
                background-color: transparent;
                border-radius: 50%;
                &:hover {
                    cursor: pointer;
                    border-color: $gold;
                    svg,
                    path {
                        fill: black;
                    }
                }
                &:nth-child(n + 2) {
                    margin-left: 10px;
                }
            }
        }

        &-label {
            &__row {
                margin: -2px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            &__col {
                margin: 2px;
            }

            &__name {
                font-weight: bold;
            }

            &__field {
                min-width: 170px;
            }
        }
    }
</style>
